/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */


/**
 * Progress bar
 */
.ngx-datatable {

  .datatable-body {
    min-height: 5px;

    .progress-linear {
      position: absolute;
      display: block;
      width: 100%;
      height: 5px;
      padding: 0;
      margin: 0;

      .container {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%;
        height: 5px;
        -webkit-transform: translate(0, 0) scale(1, 1);
        transform: translate(0, 0) scale(1, 1);
        background-color: rgba($color: #22a9b5, $alpha: 0.12);

        .bar {
          transition: all .15s linear;
          -webkit-animation: query 0.5s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
          animation: query 0.75s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
          transition: -webkit-transform .15s linear;
          transition: transform .15s linear;
          background-color: #22a9b5;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height:5px;
        }
      }
    }
  }
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

/**
 * Cut column text to fit column size
 */
.datatable-body-cell-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/**
 * Make the background and shadow transparent on modal
 */
.modal-transparent-bg {
  .modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}