//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
// $primary: #4186e0;

// Color system

$it-main-color: #9B2E1F;
$it-secondary-color: #22a9b5;
$it-complementary-color: #7367F0;

$theme-colors: (
    "primary-transparent": #EEECFD,
    "custom-danger": #EA5455,
    "custom-danger-transparent": #FCEAEA,
    "light-grey": #F4F4F4,
    "light-grey-border": #DADBDC
);


$primary: $it-complementary-color !default;
